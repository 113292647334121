.MuiButton-root.custom-button {
  border-radius: 10px;
  display: inline-flex;
  padding: 10px 25px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  text-align: center;
  text-transform: none;
}

.custom-button &:hover {
  background-color: var(--primary-700);
  color: #fff;
}
.custom-button &:active {
  background-color: var(--primary-800);
}
.custom-button &.MuiButton-contained {
  background-color: var(--primary-600-main);
  color: #fff;
}
.custom-button &.MuiButton-outlined {
  border: 1px solid var(--primary-600-main);
  color: var(--primary-600-main);
}
.custom-button.MuiButton-contained:disabled {
  opacity: 0.5;
  background: var(--primary-600-main);
  color: #ffffff;
}
.custom-button.MuiButton-outlined:disabled {
  opacity: 0.5;
  border: 1px solid var(--primary-600-main);
  color: var(--primary-600-main);
}
