.information-unavailable-root {
  background: white;
  min-height: 64px;
  padding: 25px 16px;
}

.information-unavailable-container {
  display: flex;
  flex-direction: column !important;
}
