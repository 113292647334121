.results-root .sticky-button {
  bottom: 0;
}

.results-root .sticky-button {
  bottom: 0;
}

.results-grid-container {
  width: 100% !important;
  display: block !important;
  margin: auto;
  border-bottom: 1px solid black;
  background-color: rgb(242, 242, 242);
  padding: 60px 0px 60px;
}

.results-grid-container > div {
  margin: 0px auto;
  padding-bottom: 50px;
  width: 1200px;
  max-width: 90%;
}

.results-grid-container > div:nth-of-type(2) {
  padding-top: 0px;
}

.results-grid-container .district-section-container,
.results-grid-container .accordion-container > div {
  border-radius: 20px;
  overflow: hidden;
  border: none;
}

.results-grid-container .accordion-container {
  padding-bottom: 50px;
}

.district-accordion {
  margin: 0px;
  background: white;
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px -1px rgba(0, 0, 0, 0.14),
    0px 1px 3px -3px rgba(0, 0, 0, 0.12);
  padding-bottom: 0px;
}

.district-data-container {
  width: 100%;
}

.district-section-container {
  height: 100%;
  width: 100%;
  margin-bottom: 16px;
  border-right: 1px solid black;
}

.district-section-titles {
  color: #000;
  font-family: Roobert;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 20.8px */
}
.accordion-stack .district-section-titles {
  padding: 16px 0;
  font-size: 20px;
  border-bottom: 1px solid var(--XQ-Primary-Colors-Neutrals-UI-Black, #E7E4E0);
}
.board-member {
  margin-top: 0!important;
  border-bottom: 1px solid var(--XQ-Primary-Colors-Neutrals-UI-Black, #E7E4E0);
  padding: 12px 0;
}

.board-member:last-of-type {
  border: none;
}

.board-member h4 {
  color: #000;
  font-family: Roobert;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 200%; /* 23.4px */
}

.district-section-district-info-container {
  padding: 20px;
  border-bottom: 1px solid var(--secondary-600) !important;
}

.district-section-district-info {
  text-align: left;
  margin-bottom: 15px;
}

.district-container {
  margin-bottom: 32px;
  padding: 10px;
  margin-top: 0 !important;
}

.district-container:last-child {
  border-bottom: none;
}

.district-titles .active {
  font-weight: bold;
}

.district-card-extra-information {
  width: 100%;
}

.district-titles-container {
  display: flex;
  padding: 0px 16px;
  margin-bottom: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;

  background: none;
  border: none;
  text-align: left;
}

.district-title {
  font-size: 22px;
  line-height: 30px;
  margin: 0px;
  text-align: left;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  font-family: "Roobert";
  line-height: 110%;
  text-decoration: none;
}

.district-website-link-container {
  padding: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media (min-width: 1024px) {
  .district-website-link-container {
    margin-bottom: 16px;
  }
}

.district-website-link-container a {
  padding: 6px 14px 20px 14px;
}

@media (min-width: 1024px) {
  .district-website-link-container {
    padding-top: 20px;
    margin-bottom: 0px;
  }
}

.district-website-link {
  display: inline-flex;
  padding: 0;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  text-transform: none;
  border: none;
  color: var(--primary-600-main);
  text-decoration: inherit;
}

.district-website-link .css-1otiom-MuiSvgIcon-root {
  transform: rotate(-45deg);
  color: var(--natural-black);
}

.district-website-link:hover {
  text-decoration: none;
  color: var(--XQ-Primary-Colors-Neutrals-30, #E7E4E0);
}

.district-website-link:hover svg {
  color: var(--natural-black);
}

.MuiAccordion-root {
  border-bottom: 1px solid var(--XQ-Primary-Colors-Neutrals-UI-Black, #E7E4E0);
}
.MuiAccordion-root:last-of-type {
  border: none;
  margin-top: 0px !important;
}

@media (min-width: 1024px) {
  .MuiAccordion-root::before {
    display: none;
  }
}

.Mui-expanded .district-container-summary {
  align-items: flex-start !important;
  padding-left: 30px;
  min-height: 34px !important;
}

.MuiAccordionSummary-content {
  margin: 12px 0;
}

.district-container-summary-address {
  margin: 0px;
  text-align: left;
  font-size: 14px;
}

.district-card-header {
  text-align: left;
  padding: 32px 20px 32px 40px;
  border-bottom: 1px solid black;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.district-card-header-result-count {
  display: inline-block;
  border-radius: 5px;
  font-size: 14px;
  color: #838383;
}

.district-card-header-label {
  display: block;
  font-size: 14px;
  color: #838383;
}

.district-superintendent-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.district-superintendent-container svg {
  color: var(--primary-600-main);
  width: 25px;
  height: 25px;
}

@media (min-width: 1024px) {
  .district-superintendent-containerboard-member svg {
    width: 40px;
    height: 40px;
  }
}

.results-root .email-icon {
  color: var(--XQ-Primary-Colors-Blue-Greens-60, #145758);
  text-align: center;
  gap: 8px;
  font-family: Roobert;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
}

.results-root .email-icon svg{
  width: 20px;
  height: 20px;
}

.expand-more-button{
  border-radius: 89px;
  /* background: var(--XQ-Primary-Colors-Neutrals-30, #E7E4E0); */
  background: var(rgb(242, 242, 242));
  transform: rotate(90deg);
}

.Mui-expanded .expand-more-button,
.Mui-expanded .css-yw020d-MuiAccordionSummary-expandIconWrapper {
  transform: none;
  background: var(rgb(242, 242, 242));
  transform: rotate(180deg);
}

.Mui-expanded .css-yw020d-MuiAccordionSummary-expandIconWrapper {
  width: 3em;
  height: 3em;
  border-radius: 50%;
}

.Mui-expanded .css-yw020d-MuiAccordionSummary-expandIconWrapper .district-container-summary-icon {
  margin-top: 12px;
  width: 100%;
}

.css-1fyyp8j-MuiGrid-root .css-yw020d-MuiAccordionSummary-expandIconWrapper.Mui-expanded svg {
  transform: rotate(0deg) !important;
}

.loading-text {
  color: #000;
  margin-top: 16px !important;
  text-align: center;
  font-family: Roobert;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 17.6px */
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-icon-container {
  display: inline-block;
  animation: rotate 2s linear infinite;
}

.loading-icon {
  display: block;
  margin: auto;
}

.results-grid-container .address-search input {
  padding: 25px 0px 25px 20px !important;
  font-family: "Teodor";
  background: none;
  color: white;
  font-size: 5vw;
}

.display-none {
  display: none;
}

.form-container {
  border-radius: 20px;
  overflow: hidden;
  border: none;
  padding: 0px !important;
  background-color: #fdfbf9;
  margin-top: 50px;
  text-align: left;
}

.form-container table { 
  padding: 30px;
}

.form-container .form-wrapper {
  padding: 30px;
  display: none;
  border-top: 1px solid var(--XQ-Primary-Colors-Neutrals-UI-Black, #E7E4E0);
}

.form-container .form-wrapper.open {
  display: block;
}

.form-container input,
.form-container textarea {
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #bbb;
  width: 100%;
  min-height: 40px;
}

.form-container input::placeholder,
.form-container textarea::placeholder {
  font-family: "Roobert";
}

.form-container p {
  font-weight: 100;
  color: rgba(0, 0, 0, 0.6);
  line-height: 18px;
  font-size: 16px;
}

.form-container a {
  color: rgb(20, 87, 88);
  font-family: Roobert;
  font-weight: 100;
}

.form-container .display-flex {
  display: flex;
}

.form-container .display-flex > div {
  width: 100%;
}

.form-container .display-flex > div:nth-of-type(1) {
  margin-right: 10px;
}

.form-container .display-flex > div:nth-of-type(2) {
  margin-left: 10px;
}

.form-container a:hover {
  color: rgba(0, 0, 0, .2);
}

.form-container textarea {
  height: 100px;
}

.form-container h3 {
  color: #000;
  font-family: Roobert;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  text-align: left;
  margin-top: 0;
  margin-bottom: 20px;
}

.form-container h4,
.form-container label {
  font-size: 15px;
  font-family: Roobert;
  font-weight: 100;
}

.form-container .contact-us {
  text-transform: none;
  text-decoration: underline;
  font-size: 16px;
  padding: 0;
}

.form-container .contact-us:hover {
  background: none;
}

.form-container form button {
  background-color: rgb(12, 45, 47);
  color: rgb(131, 251, 163);
  height: 60px;
  width: 150px;
  opacity: 1;
  border-radius: 112px;
  margin-top: 20px;
  margin-left: auto;
  cursor: pointer;
}

.form-container button:hover {
  background-color: rgb(131, 251, 163);
  color: rgb(12, 45, 47);
}

.form-container button[disabled] {
  color: rgb(12, 45, 47);
  background-color: rgb(131, 251, 163);
  opacity: 0.25;
}

.form-container .align-right {
  width: 100%;
  text-align: right;
}

.form-container .gfield:nth-child(3) {
  display: inline-flex;
  flex-direction: column;
  margin-right: 1%;
  width: 49%;
}

.form-container .gfield:nth-child(4) {
  display: inline-flex;
  flex-direction: column;
  margin-left: 1%;
  width: 49%;
}

.form-container form {
  text-align: right;
}

.form-container .gfield {
  text-align: left;
  margin-bottom: 10px;
}

.css-l0jafl.Mui-expanded {
  margin: 20px 0px 0px !important;
}