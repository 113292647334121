:root {
  --primary-800: #112e2f;
  --primary-700: #104142;
  --primary-600-main: #145758;
  --primary-500: #83fba3;
  --primary-400: #bdfcd7;
  --primary-300: #d8fee3;
  --primary-200: #e4ffef;
  --primary-100: #f1fff7;

  --secondary-600: #489f55;
  --secondary-500: #83ff36;
  --secondary-400: #cafc8d;
  --secondary-300: #d3ffb0;
  --secondary-200: #e7ffcb;
  --secondary-100: #f3ffe3;

  --natural-black: #000000;
  --natural-white: #ffffff;

  --natural-500: #f6f3ee;
  --natural-400: #fff8ef;
  --natural-300: #e7e4e0;
  --natural-200: #f2f2f2;
  --natural-100: #fdfbf8;

  --gradients-mellow: linear-gradient(
    180deg,
    #e7ffcb 0%,
    rgba(231, 255, 203, 0) 100%
  );
  --gradients-mild: linear-gradient(180deg, #bdfcd7 0%, #d3ffb0 100%);
  --gradients-intense: linear-gradient(
    180deg,
    #cafc8d 0%,
    rgba(202, 252, 141, 0) 43%
  );
}
