.address-lookup-container,
.homepage-hero-section-content {
  padding: 10px 20px 30px 20px;
  background: transparent;
  margin-left: auto;
  margin-right: auto;
  box-shadow: none !important;
}

.homepage-hero-section-content {
  background-color: rgb(253, 251, 248);
}

.homepage-hero-section-content:before {
  content: '';
  width: 30vw;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-image: url('../../assets/HomeBackgroundLeft.webp') !important;
  background-size: contain !important;
  background-position: top center !important;
  background-repeat: no-repeat !important;
}

.homepage-hero-section-content:after {
  content: '';
  width: 30vw;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background-image: url('../../assets/HomeBackgroundRight.webp') !important;
  background-size: contain !important;
  background-position: top center !important;
  background-repeat: no-repeat !important;
}

.homepage-hero-section-container {
  position: relative;
  padding: 0;
  overflow: hidden;
  background: transparent!important;
}

.homepage-hero-section-container iframe{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  z-index: -1;
}

@media (min-width: 900px) {
  .address-lookup-container {
    padding: 120px;
    background: white;
  }
  .homepage-hero-section-container {
    padding: 0;
  }
  .homepage-hero-section-content{
    padding: 60px 120px;
  }
}

.address-lookup-container h1 {
  color: var(--white, #fff);
  text-align: center;
  font-family: "Teodor";
  font-size: 72px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  margin-top: 40px;
  margin-bottom: 0;
}

.address-lookup-container h1 span{
  display: block;
}

.hero-header .address-search-label {
  margin-bottom: 0 !important;
  color: var(--white, #000);
  text-align: center;
  font-family: Roobert;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
}

.hero-header-heading span {
  color: #000;
}

.hero-header-text {
  font-size: 20px;
  max-width: 700px;
  margin: 20px auto;
}

.underline-container{
  text-align: center;
}

@media (min-width: 320px) {
  .address-lookup-container h1 {
    font-size: 34px;
  }
}

@media (min-width: 600px) {
  .address-lookup-container h1 {
    font-size: 69px;
  }
}

.hero-header {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 4vw;
}

.hero-header .location {
  width: 30px;
  margin-bottom: 20px;
}

.hero-image {
  text-align: center;
  position: relative;
}

.hero-image.right,
.hero-image.right .featured-image {
  padding-left: 40px;
}

.hero-image .purple.section{
  transform: rotate(-90deg);
}

.hero-image.left,
.hero-image.left .featured-image {
  padding-right: 80px;
}

.hero-image img {
  width: 100%;
  max-width: 400px;
}

.hero-image .featured-image {
  position: absolute;
  z-index: 1;
}

/* Address search for the home page */
.address-search input,
fieldset {
  border-radius: 240px !important;
  box-shadow: 0px 36px 108px -16px rgba(0, 0, 0, 0.17) !important;
  color: #000;

  font-family: Roobert;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  padding: 25px 50px !important;
  padding-left: 90px !important;
  border: none !important;
}

@media (max-width: 899px) {
  .address-search input {
    padding-left: 80px !important;
  }
  .address-search input::placeholder {
    opacity: 0;
  }
}

.home-page-search .address-search{
  padding-bottom: 0!important;
}
.home-page-search {
  position: relative;
  max-width: 700px;
  margin: auto;
  transition: all 0.3s ease-in-out;
}
.home-page-search svg {
  position: absolute;
  z-index: 11;
  height: 50px;
  width: 50px;
  top: 14px;
  left: 22px;
}
.home-page-search #fixed-search svg {
  height: 35px;
  left: 20px;
  top: 21px;
}
.home-page-search .MuiButton-root {
  border-radius: 160px !important;
  background: #83fba3 !important;
  color: #000 !important;
  position: absolute;
  right: 5px;
  bottom: 5px;
  text-align: center;
  font-family: Roobert;
  font-size: 16px !important;
  line-height: 21px;
  font-style: normal;
  font-weight: 500;
  padding: 25px;
  width: 205px;
  box-shadow: none !important;
  opacity: 1.0 !important;
}

.home-page-search .MuiButton-root:hover {
  background-color: rgb(20, 87, 88) !important;
  color: #83fba3 !important;
}

.homepage-hero-section-content .MuiButton-root {
  font-size: 22px !important;
  line-height: 18px;
}

@media (max-width: 899px) {
  .home-page-search .MuiButton-root {
    width: auto;
    font-size: 14px !important;
  }
}