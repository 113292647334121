.accordion-stack {
  text-align: left;
  padding: 24px 40px 40px 32px;
  background: white;
}

.meeting-location {
  color: var(--primary-600-main);
  align-items: center;
}

.accordion-stack .meeting-schedule {
  margin-top: 32px;
}

.location-icon {
  width: 24px;
  height: 24px;
}

.accordion-stack .MuiChip-root {
  border-radius: 40px;
  border: 1px solid #000;
  padding: 20px 10px;
}

.date-details {
  background: var(--primary-300);
  padding: 16px;
  margin-top: 20px;
}

.accordion-stack .MuiChip-root.selected-chip {
  background: var(--secondary-300);
  border-radius: 40px;
  border: 1px solid #000;
  padding: 20px 10px;
}

.meeting-time-disclaimer {
  font-size: 16px;
  color: black;
}
