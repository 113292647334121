.resources-list {

}

.resources-table {
  margin-bottom: 50px;
  background: white;
  border-radius: 20px;
  overflow: hidden;
  max-width: 1200px;
  margin: 0 auto 50px;
}

.resources-table h3 {
  margin: 0 0 15px 0;
  font-size: 70px;
}

.resources-table .right-align {
  text-align: right;
  width: 10%;
  padding-top: 0px;
}

@media (min-width: 600px) {
  .resources-table .right-align {
    padding-top: 30px;
  }
}

.resources-table h5 {
  margin: 0;
}

.resources-section .floating-icon-left {
  position: absolute;
  left: 20%;
  height: 100px;
}

.resources-section .floating-icon-right {
  position: absolute;
  right: 20%;
  height: 80px;
}

.resources-section tr p {
  color: rgba(0, 0, 0, 0.5);
  margin-top: 7px;
  font-size: 18px;
}

.resources-section tr th p {
  color: rgba(0, 0, 0, 0.87);
  max-width: 480px;
  font-size: 16px;
}

@media (min-width: 1024px) {
  .resources-section tr th p {
    line-height: 22px;
  }
}

.resources-section tr:hover {
  background: rgb(241, 255, 247);
  transition: all 0.3s ease-in-out;
}

.resources-section tr h5 {
  font-size: 24px;
}

.resources-table table {
  width: 100%;
  border-collapse: collapse;
  font-family: Roobert;
  font-size: 17px;
}

.resources-table-image {
  /* position: absolute;
  right: 20px;
  top: 20px; */
  height: 150px;
}

.resources-table table a {
  color: #145758;
}

.resources-table table a:hover {
  color: rgb(231, 228, 224);
  text-decoration: none;
}

.resources-table table tr:last-child td {
  border: none;
}

.resources-table table th {
  background-color: rgb(253, 251, 248);
  font-weight: 100;
  position: relative;
  border-bottom: 1px solid #ddd;
}

.resources-table table tr td {
  padding: 30px 30px 15px;
  width: 100%;
  display: block;
}

@media (min-width: 600px) {
  .resources-table table tr td {
    padding: 30px;
    width: 70%;
    display: table-cell;
  }
  .resources-table .right-align {
    text-align: right;
    width: 10%;
  }
}

.resources-table tr {
  border-bottom: 1px solid #ddd;
}

.resources-table tr:last-of-type {
  border: none;
}

.resources-heading {
  background: #d8fee3;
  padding-bottom: 10vh;
  border-bottom: 1px solid black !important;
  position: relative;
  text-align: center;
}

.resources-heading .book {
  width: 200px;
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}

/* .resources-heading::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/XQ_LogoWhite.png') !important;
  background-size: auto 125%;
  background-position: right -20px;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0.5;
} */

.resources-heading h1 {
  position: relative;
  z-index: 2;
  max-width: 700px;
  margin: auto;
  line-height: 80px;
  text-align: center;
}

@media (min-width: 320px) {
  .resources-heading h1,
  .resources-section h3 {
    font-size: 34px;
    line-height: 34px;
  }
}

@media (min-width: 600px) {
  .resources-heading h1,
  .resources-section h3 {
    font-size: 80px;
    line-height: 80px;
  }
}

.resources-heading p {
  max-width: 800px;
  margin: auto;
  text-align: center;
  margin-top: 30px;
  font-size: 20px;
  line-height: 26px;
  max-width: 740px;
}