/* Mobile First Default CSS */
.footer {
  padding: 2.5rem 1.25rem 1.5rem;
  a,
  a:visited,
  a:active {
    color: var(--primary-600-main);
    text-decoration: none;
  }
  a:hover {
    color: black;
  }
}
.footer-container {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.footer-section-left {
  margin-bottom: 2rem;
  margin-right: 5rem;
  flex-grow: 1;
}
.footer-logo {
  width: 120px;
}

.footer-section-right {
  flex-grow: 1;
  margin-top: 1rem;

  .right-contact-container {
    margin-bottom: 2.25rem;
    margin-top: 0;
  }
  .right-header-contact {
    font-family: Roobert, ui-sans-serif, system-ui;
    font-weight: 500;
    font-size: 1.188rem;
    line-height: 24px;
    margin-bottom: 0.5rem;
  }
  .right-contact-links {
    margin-bottom: 0.75rem;
    margin-top: 0;
  }
  .right-socials-container {
    margin-bottom: 2.25rem;
  }
  .right-header-socials {
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 0.5rem;
  }
  .right-social-icons {
    column-gap: 0.625rem;
    display: flex;
    margin-bottom: 0.75rem;
  }
}

.footer-legal {
  display: flex;
  padding-top: 0;
}

.footer-legal p {
  padding: 0;
  margin: 0;
}

.newsletter-signup {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

@media only screen and (min-width: 1024px) {
  .footer {
    padding: 3rem 2.5rem;
  }
  .footer-container {
    display: flex;
    flex-direction: row;
  }
  .footer-section-left {
    margin-bottom: 0;
  }

  .footer-section-right {
    margin-top: 0;
    padding-top: 0.625rem;
    margin-left: auto;
    flex-grow: 0;

    .right-contact-container {
      margin-bottom: 3.5rem;
    }
    .right-header-contact {
      margin-bottom: 3.5rem;
    }
    .right-socials-container {
      margin-bottom: 0;
    }
    .right-header-socials {
      display: block;
    }
    .right-social-icons {
      margin-bottom: 0;
    }
  }

  .footer-legal {
    padding-top: 0.625rem;
  }
}

@media only screen and (min-width: 1280px) {
  .footer {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }
  .footer-section-left {
    margin-right: 142px;
  }
  .footer-logo {
    width: 195px;
  }
}
