.results-resources-section {
  background: #FDFBF8;
  padding-top: 45px;
  border-bottom: 1px solid black;
}

.resources-section .resource-cards-container > .MuiGrid-root > .MuiGrid-root  {
  width: calc(100%* 12 / var(--Grid-columns)) !important;
}

@media (min-width: 768px) {
  .resources-section .resource-cards-container > .MuiGrid-root > .MuiGrid-root  {
    width: calc(100%* 6 / var(--Grid-columns)) !important;
  }
}

@media (min-width: 1024px) {
  .resources-section .resource-cards-container > .MuiGrid-root > .MuiGrid-root  {
    width: calc(100%* 4 / var(--Grid-columns)) !important;
  }
}

.resources-section > .MuiGrid-root > .MuiGrid-root {
  margin: 0 !important;
  padding-left: 0;
  padding-right: 0;
  width: 100% !important;
}

.resources-section {
  background: rgb(242, 242, 242);
  padding-top: 50px;
  padding-bottom: 10px;
  border: none !important;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  text-align: left;
  position: relative;
}

@media (min-width: 768px) {
  .resources-section {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }
}

.resources-section .resources-container {
  max-width: 100%;
}

.resources-section p {
  margin: 0;
}

.resources-section .resource-cards-container {
  padding: 0;
}

.resources-section .more-resources {
  display: none;
}

.resources-section .resources-heading,
.resources-section .resource-copy {
  display: none;
}