.address-search input,
fieldset {
  border-radius: 10px !important;
  padding: 10px 20px !important;
}

.address-search input{
  background: white;
}

.hero-image {
  display: none;
}

.location-svg {
  position: absolute;
  top: 30%;
  left: 40px;
  z-index: 100;
}

#lookup {
  position: relative;
}

@media (min-width: 900px) {
  .hero-image {
    display: block;
  }
}

.MuiSnackbarContent-message,
.MuiSnackbarContent-root,
.MuiSnackbar-anchorOriginTopCenter {
  background-color: rgb(239 68 68) !important;
  border-radius: 4px;
}
