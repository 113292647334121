.people-section .MuiGrid-container {
  display: flex;
  align-items: center;
  height: auto;
  background: initial;
  margin: auto;
  padding-top: 40px;
}
.people-section-heading {
  font-family: Teodor;
  font-size: 62px;
  font-weight: 400;
  line-height: 72px;
  text-align: left;
  margin-bottom: 15px;
}
.people-section-subheading {
  font-family: Roobert;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
}
.people-section-button-container {
  font-family: Roobert;
  font-size: 16px;
  font-weight: 650;
  line-height: 20.22px;
  text-align: left;
  margin-top: 50px;
}
.people-section-button-container .button {
  text-decoration: none;
  font-weight: 500;
  padding: 14px 22px 14px 22px;
  gap: 4px;
  border-radius: 100px;
  background-color: #0c2d2e;
  color: white;
  display: block;
  width: fit-content;
  transition: all 0.2s linear;
}
.people-section-button-container .button:hover {
  background-color: var(--primary-600-main);
}
.people-section{
  height: auto;
  border-bottom: 1px solid black;
}
.slick-slider {
  padding-top: 30px;
}
.slick-slide img {
  margin: auto;
  max-width: 250px;
  margin-top: 30px;
  margin-bottom: 20px;
  
}
.slick-slide h3 {
  font-family: 'Roobert';
  max-width: 700px;
  margin: auto;
}
.slick-dots li {
  margin: 0 3px !important;
}
.slick-dots li button:before {
  font-size: 12px !important;
}


@media (min-width: 768px) {
  .slick-dots {
    margin-bottom: -60px !important;
  }
}

@media (max-width: 899px) {
  .people-section-heading,
  .people-section-subheading,
  .people-section-button-container {
    text-align: center;
  }
  .people-section-button-container .button {
    margin: auto;
  }
  .content-section-container h2,
  .people-section-heading {
    font-size: 36px !important;
    line-height: 40px;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  .hook-content-circle {
    display: none;
  }
}