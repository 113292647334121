/*body, p {*/
/*    font-size: 1.188rem;*/
/*    font-weight: 400;*/
/*    line-height: 1.875rem;*/
/*}*/

body, h5, h6, p {

}

p {

    /* Body/Small */
    font-family: Roobert;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
}

@media (min-width: 1024px) {
    p{
        /* Body/Regular */
        font-family: Roobert;
        font-size: 19px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px; /* 157.895% */
    }
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
}

h1 {
    font-family: Teodor;
    font-size: 45px;
    font-style: normal;
    font-weight: 300;
    line-height: 52px;
}

@media (min-width: 1024px) {
    h1{
        font-family: Teodor;
        font-size: 80px;
        font-style: normal;
        font-weight: 300;
        line-height: 100px;
    }
}

h2 {
    font-family: Teodor;
    font-size: 38px;
    font-style: normal;
    font-weight: 300;
    line-height: 40px;
}

@media (min-width: 1024px) {
    h2{
        font-family: Teodor;
        font-size: 64px;
        font-style: normal;
        font-weight: 300;
        line-height: 74px;
    }
}

h3 {
    font-family: Teodor;
    font-size: 26px;
    font-style: normal;
    font-weight: 300;
    line-height: 34px;
}

@media (min-width: 1024px) {
    h3{
        font-family: Teodor;
        font-size: 48px;
        font-style: normal;
        font-weight: 300;
        line-height: 60px;
    }
}

.h3-alternate {
    font-family: Teodor;
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: 48px;
}

@media (min-width: 1024px) {
    .h3-alternate{
        font-family: Teodor;
        font-size: 58px;
        font-style: normal;
        font-weight: 300;
        line-height: 66px;
    }
}

h4 {
    font-family: Teodor;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
    margin-top: 0px;
    margin-bottom: 0px;
}

@media (min-width: 1024px) {
    h4{
        font-family: Teodor;
        font-size: 36px;
        font-style: normal;
        font-weight: 300;
        line-height: 42px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

h5 {
    font-family: Roobert;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

@media (min-width: 1024px) {
    h5{
        font-family: Roobert;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
    }
}

h6 {
    font-family: Roobert;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.display-max {
    font-size: 190px;
    line-height: 100%;
}

.xl-display {
    color: #000;
    text-align: center;
    /* Display/Display XL */
    font-family: Roobert;
    font-size: 58px;
    font-style: normal;
    font-weight: 500;
    line-height: 66px;
}

.district-section-titles {
    font-family: Roobert;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

.subtitle {
    font-family: Roobert;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}
