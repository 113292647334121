.content-section-container {
  background: #fff;
  padding: 40px 25px;
  border-bottom: 1px solid #000000;
}

.content-section-container h2 {
  color: #000;
  text-align: center;
  font-family: "Teodor";
  font-size: 62px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  margin: 40px auto;
  max-width: 600px;
}

.content-section-container p {
  color: #000;
  text-align: center;
  font-family: Roobert;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.content-section-container header{
  position: relative;
}
.content-section-container .hook-content-circle{
  position: absolute;
  bottom: -10px;
  left: 40%;
  display: none;
}
@media (min-width: 768px) {
  .content-section-container {
    padding-bottom: 150px;
  }
}
