.fixed-search {
  position: fixed;
  bottom: 43px;
  box-shadow: 0 12px 52px -16px #0003;
  justify-content: space-between;
  left: calc(50.00000000000002% - 546px / 2);
  overflow: hidden;
  position: fixed;
  width: 546px;
  will-change: var(--framer-will-change-override, transform);
  z-index: 10;
  opacity: 0;
  transform: translateY(130px);
  transition: all 0.3s ease-in-out;
}

.fixed-search input[type="text"],
.fixed-search button {
  font-size: 20px !important;
}

.fixed-search input[type="text"] {
  padding: 32px 16px 32px 75px !important;
  height: 15px!important;
  border: none!important;
}

.fixed-search fieldset {
  padding: 32px 16px !important;
  border: none !important;
}

.fixed-search button {
  padding: 24px 20px!important;
  width: 145px!important;
}

.fixed-search .location-svg{
    left: 30px;
    width: 29px;
    height: 29px;
    top: 35%;
}

.show-search .fixed-search {
  transform: translateY(0px);
  opacity: 1.0 !important;
}

.show-search .home-page-search {
  opacity: 0.0;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 899px) {
  .fixed-search {
    left: 5%;
    max-width: 90%;
  }
}