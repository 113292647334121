.election-date{
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Roobert;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 23.4px */
  margin-top: 0!important;
  padding: 16px 0;
}

.election-info {
  display: flex;
  padding: 8px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
  background: rgb(228, 255, 239);
  margin-top: 0!important;
  padding: 12px 16px 12px;
  margin-bottom: 16px!important;
}

.css-eqpfi5-MuiAccordionSummary-content {
  margin: 20px 0px 20px !important;
}