.accordion-title {
  color: #000;
  font-family: Roobert;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  text-align: left;
}

@media (min-width: 640px) {
  .accordion-title {
    font-size: 26px;
  }
}

.accordion-subtitle {
  text-align: left;
  padding-left: 2px;
}

.accordion-display .MuiAccordionDetails-root {
  padding: 0px;
}

.accordion-display .MuiAccordionSummary-expandIconWrapper {
  transform: rotate(270deg);
  width: 2em;
  height: 2em;
  margin-top: 0px;
  background: var(--XQ-Primary-Colors-Neutrals-30, #E7E4E0);
  border-radius: 50%;
}

@media (min-width: 640px) {
  .accordion-display .MuiAccordionSummary-expandIconWrapper {
    font-size: 24px;
  }
}

.accordion-display .MuiAccordionSummary-expandIconWrapper:hover,
.accordion-display .MuiAccordionSummary-expandIconWrapper:hover svg {
  background-color: rgb(12, 45, 47);
}

.accordion-display .MuiAccordionSummary-expandIconWrapper:hover svg path {
  fill: rgb(131, 251, 163);
}

.accordion-display .MuiAccordionSummary-expandIconWrapper svg {
  margin: auto;
}

.accordion-display .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  transform: rotate(0deg);
}

.accordion-display .MuiAccordion-root {
  box-shadow: none !important;
}

.accordion-display {
  margin: 0px !important;
}

.accordion-display .MuiAccordionSummary-root{
  margin: 0px !important;
  padding: 0px 32px;
  color: #000;
}

.accordion-display .MuiAccordionSummary-root:hover .MuiAccordionSummary-expandIconWrapper,
.accordion-display .MuiAccordionSummary-root:hover svg {
  background-color: rgb(12, 45, 47) !important;
}

.accordion-display .MuiAccordionSummary-root:hover svg path {
  fill: rgb(131, 251, 163);
}

.accordion-display .MuiAccordionSummary-root.Mui-expanded{
  border-bottom: none !important;
}

.accordion-display .MuiAccordionSummary-gutters {
  padding-bottom: 0;
}

.MuiAccordionDetails-root {
  padding: 0px !important;
}

@media (min-width: 1024px) {
  .MuiAccordionDetails-root {
    padding: 0 !important;
  }
}

.accordion-stack {
  padding: 16px !important;
}

@media (min-width: 1024px) {
  .accordion-stack {
    padding: 0 40px 0 32px !important;
  }
}
