@media (min-width: 1014px) {
  .district-card-body {
    width: auto;
  }
}

.district-card-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  text-align: left;
}

@media (min-width: 1014px) {
  .district-card-body {
    padding: 0px;
  }
}

.place-address {
  display: flex;
  padding: 16px 16px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border: 1px solid #000;
  background: #fff;
  color: #000;
  border-radius: 10px;

  /* Functional/Input */
  font-family: Roobert;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  flex: 1 0 0;
}

.district-card-address-content .address-search input {
  box-shadow: none !important;
}

.place-address a {
  text-decoration: none;
  padding-left: 10px;
}

.change-address-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 56px 32px 40px 32px;
  background-color: white;
  border: solid 2px #000;
  border-radius: 16px;
  text-align: center;
  width: 80%;
}

.change-address-modal h2 {
  margin: 0 0 30px 0;
}

.pac-container {
  background-color: #fff;
  z-index: 20000;
  position: fixed;
  display: inline-block;
  float: left;
  position: fixed !important;
  top: auto !important;
  bottom: 120px;
  border-radius: 45px;
  z-index: 15;
  border: none !important;
  box-shadow: 0px 36px 108px -16px rgba(0, 0, 0, 0.17) !important;
  transition: all 0.3s ease-in-out;
}

.show-search .pac-container:nth-of-type(2),
.show-search .pac-container:nth-of-type(4) {
  /* opacity: 0.0; */
}

.pac-container:nth-of-type(3),
.pac-container:nth-of-type(5) {
  /* opacity: 0.0; */
  position: fixed !important;
}

.show-search .pac-container:nth-of-type(3), 
.show-search .pac-container:nth-of-type(5) {
  opacity: 1.0;
}

.pac-container:nth-of-type(2), .pac-container:nth-of-type(4) {
  position: absolute !important;
  bottom: 11px;
  top: auto !important;
}

.pac-container .pac-item {
  padding: 12px 20px !important;
}

.pac-container:after {
  display: none;
}

.district-card-address-content {
  width: 100%;
}

.district-card-address-content svg {
  margin-bottom: 25px;
  height: 7vw;
  width: 7vw;
  max-width: 50px;
}

.district-card-address-content svg path {
  fill: white;
}

.information-unavailable-root {
  padding-left: 30px !important;
}

.district-card-address-content #lookup{
  align-self: stretch;
  background: #fff;
  border-bottom: 1px solid #000;
  color: #000;
  display: flex;
  flex: 1 0;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  font-family: Roobert;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  padding: 0 0 0 30px;
  height: 500px;
  max-height: 50vw;
  background-image: url('../../assets/ResultsHero.webp');
  background-size: cover;
  background-position: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.district-card-address-content #lookup fieldset{
  border: none;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.district-card-address-content #lookup button{
  background: none;
  color: var(--primary-600-main);    
  box-shadow: none;
  text-decoration: underline;
  color: white;
}

.district-card-address-content #lookup button svg{
  display: none;
}

.place-address-label {
  font-size: 0.8rem;
  padding: 5px 0px;
  margin: 0;
}
