.board-member {
  justify-content: space-between;
}

.board-member svg {
  color: var(--primary-600-main);
  width: 25px;
  height: 25px;
}

@media (min-width: 1024px) {
  .board-member svg {
    width: 40px;
    height: 40px;
  }
}

.MuiPopover-paper {
  background: var(--primary-600-main) !important;
  color: white !important;
  padding: 10px 20px;
  padding-left: 20px;
  text-align: center;
}
